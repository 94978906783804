footer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1em 0;
	color: var(--fc-90);
	z-index: 3;
	width: 100%;
	text-align: center;
}
