* {
	box-sizing: border-box;
}

.about__main {
	color: #a0b3b0;
	font-size: 16px;
}

main {
	position: relative;
	width: 80%;
	max-width: 20em;
	height: 35em;
	background-color: var(--info);
	border-radius: 0.8em;
	max-width: 20em;
	margin-left: auto;
	margin-right: auto;
}

.about__title {
	text-align: center;
	color: var(--fc-100);
}
.about__main img {
	display: block;
	border-radius: 0.8em 0.8em 0 0;
	width: 100%;
	height: 25em;
	object-fit: cover;
}

.about__container {
	position: absolute;
	background-color: var(--info);
	border-radius: 0 0 0.8em 0.8em;
	max-width: 20em;
	bottom: 0;
	padding: 1em;
	padding-top: 0em;
}

.name {
	color: #ffffff;
	text-transform: uppercase;
	line-height: 1.5em;
	margin: 1em;
	margin-bottom: 0.5em;
}

.name__h2,
.name-green {
	letter-spacing: 0.1em;
	margin: 0;
	margin-top: -10px;
}

.name-green {
	color: var(--highlight);
}

.logo {
	color: #dfdfdf;
	margin: 0;
}

.paragraph {
	margin: 1em;
}

.social {
	display: flex;
	justify-content: space-between;
	margin: 1em;
}

.social a {
	color: #a0b3b0;
}

.social a i {
	color: #a0b3b0;
}

@media (min-width: 800px) {
	.about__main {
		background-color: transparent;
	}
	.about__main img {
		width: 20em;
		height: 25em;
		position: absolute;
		z-index: 2;
		border-radius: 0.8em;
		right: 60%;
		bottom: 25%;
	}
	.about__container {
		bottom: 33%;
		left: 30%;
		height: 20em;
		width: 27em;
		border-radius: 0.8em;
		padding: 2em;
		padding-left: 7em;
		max-width: 1000000em;
	}
	.line {
		position: absolute;
		bottom: 72%;
		right: 80%;
		background-color: var(--highlight);
		width: 6em;
		height: 0.3em;
		z-index: 3;
		border-radius: 0.8em;
	}
}
