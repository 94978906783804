nav {
	/* -webkit-text-stroke: 1px black; */

	color: var(--fc-100);

	position: fixed;
	top: 0;
	display: flex;

	width: 100%;
	/* max-width: 50rem; */
	padding: 2rem 10rem;
	margin: 0 auto;
	/* padding: 3rem 10rem; */
	justify-content: space-around;
	z-index: 5;
}
#nav__menu {
	display: flex;
	z-index: 3;
}
.nav__link {
	color: var(--fc-90);
	text-decoration: none;
	font-size: 1.1rem;
	padding: 0 1em;
	margin-left: 10px;
	text-transform: capitalize;
}
.nav__logo {
	display: flex;

	margin-right: auto;
	margin-left: 0;
	text-transform: uppercase;
	font-size: 1.2rem;
	font-weight: 500;

	color: var(--fc-100);
}

.nav__logo * {
	letter-spacing: 2px;
	font-size: inherit;
	font-weight: inherit;
}

.nav__logo h4 {
	margin: 0;
	margin-top: 5px;
}
.nav__logo span {
	letter-spacing: 0;
	-moz-transform: matrix(-1, 0, 0, 1, 0, 0);
	-webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
	-o-transform: matrix(-1, 0, 0, 1, 0, 0);
}

#hamburger {
	display: none;
}
.hamburger__div {
	display: block;
	width: 25px;
	height: 3px;
	margin: 5px auto;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background-color: var(--fc-90);
}

@media screen and (max-width: 400px) {
	.nav__link {
		display: none;
	}
	.nav__link:first-child {
		display: flex;
	}
	.logo {
		display: flex;
	}
}

@media only screen and (max-width: 768px) {
	#nav__menu {
		position: fixed;
		right: -100%;
		top: 0;
		padding-top: 4rem;
		flex-direction: column;
		background-color: var(--bg-color);
		color: black;
		width: 100%;
		height: 110vh;
		text-align: center;
		transition: 0.3s;
		box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
	}

	#nav__menu.active {
		right: 0;
	}
	#nav__menu.active * {
		display: block;
	}

	/* .nav__link {
		margin: 1.5rem 0;
	} */
	.logo {
		margin: 0 auto 0 0;
	}
	.logo h4,
	.logo span {
		z-index: 5;
	}

	#hamburger {
		display: block;
		cursor: pointer;
		z-index: 5;
	}

	#hamburger.active {
		position: fixed;
		right: 15px;
	}
	#hamburger.active .hamburger__div:nth-child(2) {
		position: relative;
		opacity: 0;
	}

	#hamburger.active .hamburger__div:nth-child(1) {
		-webkit-transform: translateY(8px) rotate(45deg);
		transform: translateY(8px) rotate(45deg);
	}

	#hamburger.active .hamburger__div:nth-child(3) {
		-webkit-transform: translateY(-8px) rotate(-45deg);
		transform: translateY(-8px) rotate(-45deg);
	}
}
