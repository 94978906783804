.container {
	color: var(--fc-100);
	height: 100vh;
}
.container img {
	width: 100%;
	position: absolute;
	height: 110vh;
	object-fit: cover;
	z-index: 2;
}
.main-title {
	position: absolute;
	top: 30%;
	left: 50%;
	/* font-size: calc(10vw + 5px); */
	font-size: 13.8em;
	transform: translate(-50%, -30%);

	z-index: 2;
}
.sky {
	z-index: 1 !important;
}
.rock {
	width: clamp(5vw, 15vw, 30vw) !important;
	height: auto !important;
	bottom: 0;
}

.right {
	right: 0;
}

.middleground,
.furtherground {
	height: auto !important;
	bottom: -50px;
}

.content {
	color: var(--fc-100);
	width: 100%;
	background: var(--bg-color);
	box-shadow: 0px -15px 20px var(--bg-color);
	min-height: 110vh;
	z-index: 2;
	position: absolute;
	overflow: hidden;
	padding-bottom: 2em;
}

.title {
	font-weight: 300;
	font-size: 4em;
	color: #fff;

	margin-top: 20vh;
	text-align: center;
}
.news-title {
	margin-top: 30vh;
}
.content-cards {
	display: flex;
	min-height: 60vh;
	text-align: center;
	/* max-width: 54vw; */
	margin: 2.5vh auto 0 auto;
}

.content-card:first-child {
	margin-left: auto;
}
.content-card:last-child {
	margin-right: auto;
}
.content-cards img,
.content-card__img {
	border-radius: 8px;
	width: 15rem;
	height: 22rem;
}
.content-card__img {
	background-position: center;
	background-repeat: no-repeat;
	/* background-size: 15em 22em; */
	background-size: cover;
}
.content-card {
	margin-right: 45px;
}
.content-card__link {
	color: white;
	text-decoration: none;
}
.content-card h3 {
	/* max-width: 19ch; */
	max-width: 15rem;
	margin: 0;

	font-size: 1.25em;
	font-weight: 400;
	position: relative;
	bottom: 3em;
}
.water-mark {
	position: absolute;
	top: 47vh;
	left: -22vw;
	text-transform: uppercase;
	/* color: black; */
	color: hsla(0, 0%, 19%, 0.69);
	font-size: 30em;

	z-index: -1;
}
.map-of-iceland {
	position: absolute;
	/* top: 100vh; */
	left: 0;

	z-index: -1;
}
/* it brakes all the other sites */
/* footer {
	top: 182vh;
} */

/* screen sizes less than or equal to iphone 8 */
@media screen and (max-width: 380px) {
	nav {
		padding: 1em;
	}
	.main-title {
		font-size: 6.8em;
	}
	.middleground {
		/* height: 450px !important; */
		height: 350px !important;
		bottom: -50px;
	}
	.furtherground {
		/* height: 600px !important; */
		height: 500px !important;
	}
	.rock {
		/* width: 100px !important; */
		width: 110px !important;
	}
	.content {
		font-size: 0.6rem;
		/* min-height: 120vh;
		box-shadow: 0 -150px 15px var(--bg-color); */
	}

	.content-cards {
		flex-direction: column;
	}
	.content-cards img {
		width: 22em;
		height: 32em;
	}
	.content-card,
	.news {
		margin: 0 auto;
	}

	.content-card h3 {
		font-size: 2em;
	}

	.water-mark {
		left: -45vw;
		top: 67vh;
		font-size: 25em;
	}
}

@media screen and (min-width: 381px) {
	body {
		font-size: 15px;
	}
	nav {
		padding: 1em;
	}
	.main-title {
		font-size: 7.5em;
	}
	.middleground {
		height: 450px !important;
		bottom: -50px;
	}
	.furtherground {
		height: 600px !important;
	}
	.rock {
		width: 100px !important;
	}
	.title {
		font-size: 2.2em;
		word-break: break-word;
	}
	.content-cards {
		flex-direction: column;
	}
	.content-card,
	.news {
		margin: 0 auto;
	}
}
@media screen and (min-width: 414px) {
	.rock {
		width: 150px !important;
	}
}
@media screen and (min-width: 500px) {
	.content-cards {
		display: grid;
		grid-template-columns: auto auto;
		grid-auto-rows: auto;
		justify-content: center;
		align-items: center;
		gap: 10px;
		column-gap: 20px;
	}
}
@media screen and (min-width: 820px) {
	.content-cards {
		display: flex;
		flex-direction: row;
		gap: 25px;
	}
	.content-card {
		margin: 0;
	}
}

@media screen and (min-width: 1000px) {
	h1 {
		font-size: 30px;
	}
	.furtherground,
	.middleground {
		height: auto !important;
	}
	.rock {
		/* width: 0.19vw !important; */
	}
	.right {
		/* width: 275px !important; */
		width: 200px !important;
		height: auto !important;
	}
	.main-title {
		font-size: 14em;
		font-weight: 400;
	}
	.content-cards {
		display: flex;
	}
}

@media screen and (min-width: 1100px) {
	.rock {
		width: 160px !important;
	}
	.right {
		width: 220px !important;
	}
}
@media screen and (min-width: 1200px) {
	.right {
		width: 240px !important;
	}
}
@media screen and (min-width: 1300px) {
	.right {
		width: 250px !important;
	}
}
