.tours__h1 {
	color: var(--fc-100);
	text-align: center;
}

.tours--cards {
	display: grid;
	grid-template-columns: 0.8fr;
	grid-auto-rows: auto;
	gap: 45px;
	justify-content: center;
	align-items: center;
}

.tour--card {
	width: 100%;
	/* max-width: 20rem; */
	/* height: 50vh; */
	height: clamp(50vh, 60vh, 70vh);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 8px;
}

.tour--card__h4 {
	color: var(--fc-100);
	margin-top: 42vh;
	margin-top: clamp(42vh, 52vh, 62vh);
	text-align: center;
}

.tour--card:focus {
	opacity: 0.7;
	transition: opacity 500ms;
}
.tour--card:focus .tour--card__h4 {
	color: var(--fc-100);

	margin-top: clamp(41.5vh, 43.5vh, 49.5vh);
}
/* if device has a mouce / if device is not a phone */
@media (pointer: fine) {
	.tour--card__h4 {
		color: var(--fc-90);
		margin-top: clamp(42vh, 44vh, 50vh);
		transition: all 250ms;
	}
	.tour--card:hover {
		opacity: 0.7;
		transition: opacity 500ms;
	}
	.tour--card:hover .tour--card__h4 {
		color: var(--fc-100);
		/* color: var(--highlight); */
		/* margin-top: clamp(42vh, 52vh, 62vh); */
		margin-top: clamp(41.5vh, 43.5vh, 49.5vh);
	}
}

@media screen and (min-width: 500px) {
	.tours--cards {
		grid-template-columns: auto auto;
		gap: 20px;
	}
	.tour--card {
		height: clamp(50vh, 52vh, 58vh);
	}
	.tour--card__h4 {
		padding: 0 0.25em;
		font-size: 1.4rem;
		margin-top: clamp(43vh, 45vh, 51vh);
	}
}
@media screen and (min-width: 800px) {
	.tours--cards {
		grid-template-columns: auto auto auto;
	}
	.tour--card__h4 {
		/* margin-top: ; */
	}
}
