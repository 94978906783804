.contact-us__container {
	box-sizing: border-box;
	max-width: 1170px;
	margin: auto;
	margin-top: 10vh;
	padding: 1em;

	color: var(--fc-90);
}

.contact-us__container ul {
	list-style: none;
	padding: 0;
}

.contact-us__wrapper {
	box-shadow: 0 0 20px 0 var(--shadow);
}

.contact-us__wrapper > * {
	padding: 1em;
}

.company-info {
	background: var(--info);
	color: #9fb1af;
}

.company-info h3,
.company-info ul {
	text-align: center;
	margin: 0 0 1rem 0;
}

.company-info ul p {
	display: inline;
}

.contact {
	background: var(--form-bg);
}

/* FORM STYLES */
.contact form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.contact h3 {
	color: var(--white);
}

.msg {
	color: var(--highlight);
	font-weight: bold;
}

.contact form label {
	display: block;
}

.contact form p {
	margin: 0;
}

.contact form .full {
	grid-column: 1 / 3;
}

.contact form button,
.contact form input,
.contact form textarea {
	width: 100%;
	padding: 1em;
	border: 1px solid #c9e6ff;
}

.contact form button {
	background: var(--highlight);
	border: 0;
	text-transform: uppercase;
	cursor: pointer;
}

.contact form button:hover,
.contact form button:focus {
	background: #25e7b3;
	color: #fff;
	outline: 0;
	transition: background-color 2s ease-out;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
	.contact-us__wrapper {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}

	.contact-us__wrapper > * {
		padding: 2em;
	}

	.company-info h3,
	.company-info ul,
	.brand {
		text-align: left;
	}
}

/* SMALL SCREENS */
@media (max-width: 400px) {
	.contact form {
		display: flex;
		flex-direction: column;
	}

	.contact form p {
		margin-top: 1em;
	}

	.contact-us__container {
		padding: 0em;
	}
}
