@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
nav {
	/* -webkit-text-stroke: 1px black; */

	color: var(--fc-100);

	position: fixed;
	top: 0;
	display: -webkit-flex;
	display: flex;

	width: 100%;
	/* max-width: 50rem; */
	padding: 2rem 10rem;
	margin: 0 auto;
	/* padding: 3rem 10rem; */
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	z-index: 5;
}
#nav__menu {
	display: -webkit-flex;
	display: flex;
	z-index: 3;
}
.nav__link {
	color: var(--fc-90);
	text-decoration: none;
	font-size: 1.1rem;
	padding: 0 1em;
	margin-left: 10px;
	text-transform: capitalize;
}
.nav__logo {
	display: -webkit-flex;
	display: flex;

	margin-right: auto;
	margin-left: 0;
	text-transform: uppercase;
	font-size: 1.2rem;
	font-weight: 500;

	color: var(--fc-100);
}

.nav__logo * {
	letter-spacing: 2px;
	font-size: inherit;
	font-weight: inherit;
}

.nav__logo h4 {
	margin: 0;
	margin-top: 5px;
}
.nav__logo span {
	letter-spacing: 0;
	-moz-transform: matrix(-1, 0, 0, 1, 0, 0);
	-webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
	-o-transform: matrix(-1, 0, 0, 1, 0, 0);
}

#hamburger {
	display: none;
}
.hamburger__div {
	display: block;
	width: 25px;
	height: 3px;
	margin: 5px auto;
	transition: all 0.3s ease-in-out;
	background-color: var(--fc-90);
}

@media screen and (max-width: 400px) {
	.nav__link {
		display: none;
	}
	.nav__link:first-child {
		display: -webkit-flex;
		display: flex;
	}
	.logo {
		display: -webkit-flex;
		display: flex;
	}
}

@media only screen and (max-width: 768px) {
	#nav__menu {
		position: fixed;
		right: -100%;
		top: 0;
		padding-top: 4rem;
		-webkit-flex-direction: column;
		        flex-direction: column;
		background-color: var(--bg-color);
		color: black;
		width: 100%;
		height: 110vh;
		text-align: center;
		transition: 0.3s;
		box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
	}

	#nav__menu.active {
		right: 0;
	}
	#nav__menu.active * {
		display: block;
	}

	/* .nav__link {
		margin: 1.5rem 0;
	} */
	.logo {
		margin: 0 auto 0 0;
	}
	.logo h4,
	.logo span {
		z-index: 5;
	}

	#hamburger {
		display: block;
		cursor: pointer;
		z-index: 5;
	}

	#hamburger.active {
		position: fixed;
		right: 15px;
	}
	#hamburger.active .hamburger__div:nth-child(2) {
		position: relative;
		opacity: 0;
	}

	#hamburger.active .hamburger__div:nth-child(1) {
		-webkit-transform: translateY(8px) rotate(45deg);
		transform: translateY(8px) rotate(45deg);
	}

	#hamburger.active .hamburger__div:nth-child(3) {
		-webkit-transform: translateY(-8px) rotate(-45deg);
		transform: translateY(-8px) rotate(-45deg);
	}
}

/* roboto font */

:root {
	/* background color */
	--bg-color: #151515;

	/* font family */
	--ff-normal: "Roboto", sans-serif;
	--ff-accent: "Roboto", sans-serif;

	/* font color */
	--fc-100: hsl(0, 0%, 100%);
	--fc-90: hsl(0, 0%, 90%);
	--fc-80: hsl(0, 0%, 80%);

	--form-bg: #24333c;
	--info: #2b3e47;
	--highlight: hsl(164, 74%, 45%);
	--white: #ddd;
	--shadow: #182229;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* font-family: "Poppins", sans-serif; */
	font-family: "Roboto", sans-serif;
	font-family: var(--ff-normal);
}

body {
	background-color: #151515;
	background-color: var(--bg-color);
}

/* font scale (major third, base size: 16px)*/

html {
	font-size: 16px;
} /*16px*/

body {
	font-weight: 400;
	line-height: 1.75;
}

p {
	font-family: "Roboto", sans-serif;
	font-family: var(--ff-normal);
	margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 3rem 0 1.38rem;
	font-family: "Roboto", sans-serif;
	font-family: var(--ff-accent);
	font-weight: 400;
	line-height: 1.3;
}

h1 {
	margin-top: 0;
	font-size: 3.052rem;
}

h2 {
	font-size: 2.441rem;
}

h3 {
	font-size: 1.953rem;
}

h4 {
	font-size: 1.563rem;
}

h5 {
	font-size: 1.25rem;
}

img {
	object-fit: cover;
	object-position: center;
}

footer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1em 0;
	color: var(--fc-90);
	z-index: 3;
	width: 100%;
	text-align: center;
}

.container {
	color: var(--fc-100);
	height: 100vh;
}
.container img {
	width: 100%;
	position: absolute;
	height: 110vh;
	object-fit: cover;
	z-index: 2;
}
.main-title {
	position: absolute;
	top: 30%;
	left: 50%;
	/* font-size: calc(10vw + 5px); */
	font-size: 13.8em;
	-webkit-transform: translate(-50%, -30%);
	        transform: translate(-50%, -30%);

	z-index: 2;
}
.sky {
	z-index: 1 !important;
}
.rock {
	width: clamp(5vw, 15vw, 30vw) !important;
	height: auto !important;
	bottom: 0;
}

.right {
	right: 0;
}

.middleground,
.furtherground {
	height: auto !important;
	bottom: -50px;
}

.content {
	color: var(--fc-100);
	width: 100%;
	background: var(--bg-color);
	box-shadow: 0px -15px 20px var(--bg-color);
	min-height: 110vh;
	z-index: 2;
	position: absolute;
	overflow: hidden;
	padding-bottom: 2em;
}

.title {
	font-weight: 300;
	font-size: 4em;
	color: #fff;

	margin-top: 20vh;
	text-align: center;
}
.news-title {
	margin-top: 30vh;
}
.content-cards {
	display: -webkit-flex;
	display: flex;
	min-height: 60vh;
	text-align: center;
	/* max-width: 54vw; */
	margin: 2.5vh auto 0 auto;
}

.content-card:first-child {
	margin-left: auto;
}
.content-card:last-child {
	margin-right: auto;
}
.content-cards img,
.content-card__img {
	border-radius: 8px;
	width: 15rem;
	height: 22rem;
}
.content-card__img {
	background-position: center;
	background-repeat: no-repeat;
	/* background-size: 15em 22em; */
	background-size: cover;
}
.content-card {
	margin-right: 45px;
}
.content-card__link {
	color: white;
	text-decoration: none;
}
.content-card h3 {
	/* max-width: 19ch; */
	max-width: 15rem;
	margin: 0;

	font-size: 1.25em;
	font-weight: 400;
	position: relative;
	bottom: 3em;
}
.water-mark {
	position: absolute;
	top: 47vh;
	left: -22vw;
	text-transform: uppercase;
	/* color: black; */
	color: hsla(0, 0%, 19%, 0.69);
	font-size: 30em;

	z-index: -1;
}
.map-of-iceland {
	position: absolute;
	/* top: 100vh; */
	left: 0;

	z-index: -1;
}
/* it brakes all the other sites */
/* footer {
	top: 182vh;
} */

/* screen sizes less than or equal to iphone 8 */
@media screen and (max-width: 380px) {
	nav {
		padding: 1em;
	}
	.main-title {
		font-size: 6.8em;
	}
	.middleground {
		/* height: 450px !important; */
		height: 350px !important;
		bottom: -50px;
	}
	.furtherground {
		/* height: 600px !important; */
		height: 500px !important;
	}
	.rock {
		/* width: 100px !important; */
		width: 110px !important;
	}
	.content {
		font-size: 0.6rem;
		/* min-height: 120vh;
		box-shadow: 0 -150px 15px var(--bg-color); */
	}

	.content-cards {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.content-cards img {
		width: 22em;
		height: 32em;
	}
	.content-card,
	.news {
		margin: 0 auto;
	}

	.content-card h3 {
		font-size: 2em;
	}

	.water-mark {
		left: -45vw;
		top: 67vh;
		font-size: 25em;
	}
}

@media screen and (min-width: 381px) {
	body {
		font-size: 15px;
	}
	nav {
		padding: 1em;
	}
	.main-title {
		font-size: 7.5em;
	}
	.middleground {
		height: 450px !important;
		bottom: -50px;
	}
	.furtherground {
		height: 600px !important;
	}
	.rock {
		width: 100px !important;
	}
	.title {
		font-size: 2.2em;
		word-break: break-word;
	}
	.content-cards {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
	.content-card,
	.news {
		margin: 0 auto;
	}
}
@media screen and (min-width: 414px) {
	.rock {
		width: 150px !important;
	}
}
@media screen and (min-width: 500px) {
	.content-cards {
		display: grid;
		grid-template-columns: auto auto;
		grid-auto-rows: auto;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		grid-gap: 10px;
		gap: 10px;
		grid-column-gap: 20px;
		-webkit-column-gap: 20px;
		        column-gap: 20px;
	}
}
@media screen and (min-width: 820px) {
	.content-cards {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		grid-gap: 25px;
		gap: 25px;
	}
	.content-card {
		margin: 0;
	}
}

@media screen and (min-width: 1000px) {
	h1 {
		font-size: 30px;
	}
	.furtherground,
	.middleground {
		height: auto !important;
	}
	.rock {
		/* width: 0.19vw !important; */
	}
	.right {
		/* width: 275px !important; */
		width: 200px !important;
		height: auto !important;
	}
	.main-title {
		font-size: 14em;
		font-weight: 400;
	}
	.content-cards {
		display: -webkit-flex;
		display: flex;
	}
}

@media screen and (min-width: 1100px) {
	.rock {
		width: 160px !important;
	}
	.right {
		width: 220px !important;
	}
}
@media screen and (min-width: 1200px) {
	.right {
		width: 240px !important;
	}
}
@media screen and (min-width: 1300px) {
	.right {
		width: 250px !important;
	}
}

* {
	box-sizing: border-box;
}

.about__main {
	color: #a0b3b0;
	font-size: 16px;
}

main {
	position: relative;
	width: 80%;
	max-width: 20em;
	height: 35em;
	background-color: var(--info);
	border-radius: 0.8em;
	max-width: 20em;
	margin-left: auto;
	margin-right: auto;
}

.about__title {
	text-align: center;
	color: var(--fc-100);
}
.about__main img {
	display: block;
	border-radius: 0.8em 0.8em 0 0;
	width: 100%;
	height: 25em;
	object-fit: cover;
}

.about__container {
	position: absolute;
	background-color: var(--info);
	border-radius: 0 0 0.8em 0.8em;
	max-width: 20em;
	bottom: 0;
	padding: 1em;
	padding-top: 0em;
}

.name {
	color: #ffffff;
	text-transform: uppercase;
	line-height: 1.5em;
	margin: 1em;
	margin-bottom: 0.5em;
}

.name__h2,
.name-green {
	letter-spacing: 0.1em;
	margin: 0;
	margin-top: -10px;
}

.name-green {
	color: var(--highlight);
}

.logo {
	color: #dfdfdf;
	margin: 0;
}

.paragraph {
	margin: 1em;
}

.social {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin: 1em;
}

.social a {
	color: #a0b3b0;
}

.social a i {
	color: #a0b3b0;
}

@media (min-width: 800px) {
	.about__main {
		background-color: transparent;
	}
	.about__main img {
		width: 20em;
		height: 25em;
		position: absolute;
		z-index: 2;
		border-radius: 0.8em;
		right: 60%;
		bottom: 25%;
	}
	.about__container {
		bottom: 33%;
		left: 30%;
		height: 20em;
		width: 27em;
		border-radius: 0.8em;
		padding: 2em;
		padding-left: 7em;
		max-width: 1000000em;
	}
	.line {
		position: absolute;
		bottom: 72%;
		right: 80%;
		background-color: var(--highlight);
		width: 6em;
		height: 0.3em;
		z-index: 3;
		border-radius: 0.8em;
	}
}

.contact-us__container {
	box-sizing: border-box;
	max-width: 1170px;
	margin: auto;
	margin-top: 10vh;
	padding: 1em;

	color: var(--fc-90);
}

.contact-us__container ul {
	list-style: none;
	padding: 0;
}

.contact-us__wrapper {
	box-shadow: 0 0 20px 0 var(--shadow);
}

.contact-us__wrapper > * {
	padding: 1em;
}

.company-info {
	background: var(--info);
	color: #9fb1af;
}

.company-info h3,
.company-info ul {
	text-align: center;
	margin: 0 0 1rem 0;
}

.company-info ul p {
	display: inline;
}

.contact {
	background: var(--form-bg);
}

/* FORM STYLES */
.contact form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.contact h3 {
	color: var(--white);
}

.msg {
	color: var(--highlight);
	font-weight: bold;
}

.contact form label {
	display: block;
}

.contact form p {
	margin: 0;
}

.contact form .full {
	grid-column: 1 / 3;
}

.contact form button,
.contact form input,
.contact form textarea {
	width: 100%;
	padding: 1em;
	border: 1px solid #c9e6ff;
}

.contact form button {
	background: var(--highlight);
	border: 0;
	text-transform: uppercase;
	cursor: pointer;
}

.contact form button:hover,
.contact form button:focus {
	background: #25e7b3;
	color: #fff;
	outline: 0;
	transition: background-color 2s ease-out;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
	.contact-us__wrapper {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}

	.contact-us__wrapper > * {
		padding: 2em;
	}

	.company-info h3,
	.company-info ul,
	.brand {
		text-align: left;
	}
}

/* SMALL SCREENS */
@media (max-width: 400px) {
	.contact form {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.contact form p {
		margin-top: 1em;
	}

	.contact-us__container {
		padding: 0em;
	}
}

.wrapper {
	margin-top: 6em;
}

.blog-card {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin: 1rem 1rem;
	box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
	margin-bottom: 1.6%;
	background: var(--form-bg);
	line-height: 1.4;
	font-family: sans-serif;
	border-radius: 5px;
	overflow: hidden;
	z-index: 0;
}

.blog-card a {
	color: inherit;
}

.blog-card a:hover {
	color: var(--highlight);
}

.blog-card:hover .photo {
	-webkit-transform: scale(1.3) rotate(3deg);
	        transform: scale(1.3) rotate(3deg);
}

.blog-card .meta {
	position: relative;
	z-index: 0;
	height: 200px;
}

.blog-card .photo {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
	background-position: center;
	transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
}

.blog-card .details,
.blog-card .details ul {
	margin: auto;
	padding: 0;
	list-style: none;
}

.blog-card .details {
	position: absolute;
	top: 0;
	bottom: 0;
	left: -100%;
	margin: auto;
	transition: left 0.2s;
	background: rgba(0, 0, 0, 0.6);
	color: #fff;
	padding: 10px;
	width: 100%;
	font-size: 0.9rem;
}

.blog-card .details a {
	-webkit-text-decoration: dotted underline;
	        text-decoration: dotted underline;
}

.blog-card .details ul li {
	display: inline-block;
}

.blog-card .details .author:before {
	font-family: FontAwesome;
	margin-right: 10px;
	content: "\f007";
}

.blog-card .details .date:before {
	font-family: FontAwesome;
	margin-right: 10px;
	content: "\f133";
}

.blog-card .details .tags ul:before {
	font-family: FontAwesome;
	content: "\f02b";
	margin-right: 10px;
}

.blog-card .details .tags li {
	margin-right: 2px;
}

.blog-card .details .tags li:first-child {
	margin-left: -4px;
}

.blog-card .description {
	padding: 1rem;
	background: var(--form-bg);
	position: relative;
	z-index: 1;
}

.blog-card .description h1,
.blog-card .description h2 {
	font-family: Poppins, sans-serif;
	color: white;
}

.blog-card .description h1 {
	line-height: 1;
	margin: 0;
	font-size: 1.7rem;
}

.blog-card .description h2 {
	font-size: 1rem;
	font-weight: 300;
	text-transform: uppercase;
	color: #c7c7c7;
	margin-top: 5px;
}

.blog-card .description .read-more {
	text-align: right;
}

.blog-card .description .read-more a {
	color: var(--highlight);
	display: inline-block;
	position: relative;
}

.blog-card .description .read-more a:after {
	content: "\f061";
	font-family: FontAwesome;
	margin-left: -10px;
	opacity: 0;
	vertical-align: middle;
	transition: margin 0.3s, opacity 0.3s;
}

.blog-card .description .read-more a:hover:after {
	margin-left: 5px;
	opacity: 1;
}

.blog-card p {
	position: relative;
	margin: 1rem 0 0;
	color: #9b9b9b;
}

.blog-card p:first-of-type {
	margin-top: 1.25rem;
}

.blog-card p:first-of-type:before {
	content: "";
	position: absolute;
	height: 5px;
	background: var(--highlight);
	width: 35px;
	top: -0.75rem;
	border-radius: 3px;
}

.blog-card:hover .details {
	left: 0%;
}

/* big screens */

@media (min-width: 640px) {
	.wrapper {
		margin-top: 10vh;
	}
	.blog-card {
		-webkit-flex-direction: row;
		        flex-direction: row;
		max-width: 700px;
		margin: 1rem auto;
	}
	.blog-card .meta {
		-webkit-flex-basis: 40%;
		        flex-basis: 40%;
		height: auto;
	}
	.blog-card .description {
		-webkit-flex-basis: 60%;
		        flex-basis: 60%;
	}
	.blog-card .description:before {
		-webkit-transform: skewX(-3deg);
		        transform: skewX(-3deg);
		content: "";
		background: var(--form-bg);
		width: 30px;
		position: absolute;
		left: -10px;
		top: 0;
		bottom: 0;
		z-index: -1;
	}
	.blog-card.alt {
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}
	.blog-card.alt .description:before {
		left: inherit;
		right: -10px;
		-webkit-transform: skew(3deg);
		        transform: skew(3deg);
	}
	.blog-card.alt .details {
		padding-left: 25px;
	}
}

.tours__h1 {
	color: var(--fc-100);
	text-align: center;
}

.tours--cards {
	display: grid;
	grid-template-columns: 0.8fr;
	grid-auto-rows: auto;
	grid-gap: 45px;
	gap: 45px;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.tour--card {
	width: 100%;
	/* max-width: 20rem; */
	/* height: 50vh; */
	height: clamp(50vh, 60vh, 70vh);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 8px;
}

.tour--card__h4 {
	color: var(--fc-100);
	margin-top: 42vh;
	margin-top: clamp(42vh, 52vh, 62vh);
	text-align: center;
}

.tour--card:focus {
	opacity: 0.7;
	transition: opacity 500ms;
}
.tour--card:focus .tour--card__h4 {
	color: var(--fc-100);

	margin-top: clamp(41.5vh, 43.5vh, 49.5vh);
}
/* if device has a mouce / if device is not a phone */
@media (pointer: fine) {
	.tour--card__h4 {
		color: var(--fc-90);
		margin-top: clamp(42vh, 44vh, 50vh);
		transition: all 250ms;
	}
	.tour--card:hover {
		opacity: 0.7;
		transition: opacity 500ms;
	}
	.tour--card:hover .tour--card__h4 {
		color: var(--fc-100);
		/* color: var(--highlight); */
		/* margin-top: clamp(42vh, 52vh, 62vh); */
		margin-top: clamp(41.5vh, 43.5vh, 49.5vh);
	}
}

@media screen and (min-width: 500px) {
	.tours--cards {
		grid-template-columns: auto auto;
		grid-gap: 20px;
		gap: 20px;
	}
	.tour--card {
		height: clamp(50vh, 52vh, 58vh);
	}
	.tour--card__h4 {
		padding: 0 0.25em;
		font-size: 1.4rem;
		margin-top: clamp(43vh, 45vh, 51vh);
	}
}
@media screen and (min-width: 800px) {
	.tours--cards {
		grid-template-columns: auto auto auto;
	}
	.tour--card__h4 {
		/* margin-top: ; */
	}
}

