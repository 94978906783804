@import url("./css/Nav.css");

/* roboto font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
	/* background color */
	--bg-color: #151515;

	/* font family */
	--ff-normal: "Roboto", sans-serif;
	--ff-accent: "Roboto", sans-serif;

	/* font color */
	--fc-100: hsl(0, 0%, 100%);
	--fc-90: hsl(0, 0%, 90%);
	--fc-80: hsl(0, 0%, 80%);

	--form-bg: #24333c;
	--info: #2b3e47;
	--highlight: hsl(164, 74%, 45%);
	--white: #ddd;
	--shadow: #182229;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* font-family: "Poppins", sans-serif; */
	font-family: var(--ff-normal);
}

body {
	background-color: var(--bg-color);
}

/* font scale (major third, base size: 16px)*/

html {
	font-size: 16px;
} /*16px*/

body {
	font-weight: 400;
	line-height: 1.75;
}

p {
	font-family: var(--ff-normal);
	margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 3rem 0 1.38rem;
	font-family: var(--ff-accent);
	font-weight: 400;
	line-height: 1.3;
}

h1 {
	margin-top: 0;
	font-size: 3.052rem;
}

h2 {
	font-size: 2.441rem;
}

h3 {
	font-size: 1.953rem;
}

h4 {
	font-size: 1.563rem;
}

h5 {
	font-size: 1.25rem;
}

img {
	object-fit: cover;
	object-position: center;
}
